import React from "react";

import loadingGif from "../assets/load.gif";

//Small Component to display a loading gif and a text
function Loading({ text }) {
  return (
    <div>
      <img
        src={loadingGif}
        alt="Loading Circle"
        style={{
          width: "60px",
            display: "block",
            "marginLeft": "auto",
            "marginRight": "auto",
        }}
      />
      <h3>{text}</h3>
    </div>
  );
}

export default Loading;

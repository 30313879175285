import React, { useState } from "react";
import DOMPurify from "dompurify";

//The LicenseTerms component, renders the latest license terms and a checkbox to accept them
const LicenseTerms = ({ onSubmit, licenseTerms, licenseWasSent, DownloadDocument, waitingForDocumentTypeDownload }) => {
  const [waitForCreation, setWaitforCreation] = useState(false);

  const handleSubmitLicense = (e) => {
    setWaitforCreation(true);
    onSubmit();
  };

  return (
    <div id="license-terms-container">
      {licenseTerms && (
        <>
          <h2 id="license-terms-header">Lizenzbedingungen</h2>
          <div
            id="license-terms"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(licenseTerms.content),
            }}
          />
          <div id="buttons-and-accept">
            <div>
              <button
                id="download-license-terms"
                type="button"
                onClick={() => {
                  DownloadDocument(1);
                }}
                disabled={licenseWasSent || waitingForDocumentTypeDownload.includes(1)}
              >
                {waitingForDocumentTypeDownload.includes(1)? "PDF wird erstellt" : "Lizenzbedingungen herunterladen"}
              </button>
              <button
                id="download-agbs"
                type="button"
                onClick={() => DownloadDocument(2)}
                disabled={licenseWasSent || waitingForDocumentTypeDownload.includes(2)}
              >
                {waitingForDocumentTypeDownload.includes(2)? "PDF wird erstellt" : "AGB herunterladen"}
              </button>
            </div>
            <div id="accept-license-terms-div">
              <label id="accept-license-terms-label">
                Ich akzeptiere die Lizenzbedingungen
                <input
                  type="checkbox"
                  id="accept-terms-checkbox"
                  name="accept-terms"
                  value="accept-terms-value"
                  required
                  disabled={licenseWasSent}
                />
              </label>
              <button
                type="submit"
                id="customer-license-form-submit-button"
                onClick={handleSubmitLicense}
                disabled={licenseWasSent || waitForCreation}
              >
                {waitForCreation ? 'Wird verarbeitet...' : 'Lizenz zahlungspflichtig bestellen'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LicenseTerms;

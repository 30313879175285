import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomerData from "./components/CustomerData";
import ModuleParts from "./components/ModuleParts";
import LicenseTerms from "./components/LicenseTerms";
import Loading from "./components/Loading";

function App() {
  const [licenseWasSent, setLicenseWasSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customerData, setCustomerData] = useState({
    name: "",
    firstname: "",
    lastname: "",
    street: "",
    postcode: "",
    location: "",
    phoneNumber: "",
    fax: "",
    email: "",
    numberOfLocations: 1,
    licenseId: -1,
    selectedModulePartIds: [],
    licenseWasSent: { licenseWasSent },
  });

  const [moduleParts, setModuleParts] = useState([]);
  const [licenseTerms, setLicenseTerms] = useState([]);

  const [customerIdentifier, setCustomerIdentifier] = useState("");
  const [advoModuleName, setAdvoModuleName] = useState("");

  const [waitingForDocumentTypeDownload, setWaitingForDocumentTypeDownload] = useState([]);

  const API_URL = process.env.REACT_APP_LICENSESERVICE_BACKEND;

  //Called once when the page is first rendered
  useEffect(() => {
    initializeUrlParameters();
  }, []);

  useEffect(() => {
    if (customerIdentifier && advoModuleName) {
      //Remove the parameters from the URL so it doesn't get displayed in the browser
      window.history.replaceState({}, document.title, "/");
      fetchInitialData();
    }
  }, [customerIdentifier, advoModuleName]);

  function handleCustomerDataChange(e) {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  }

  // Handles the selection of ModuleParts
  const handleModulePartSelection = (modulePart, checked) => {
    if (checked) {
      if (!customerData.selectedModulePartIds.includes(modulePart.id)) {
        setCustomerData((prevData) => ({
          ...prevData,
          selectedModulePartIds: [...prevData.selectedModulePartIds, modulePart.id],
        }));
      }
    } else {
      setCustomerData((prevData) => ({
        ...prevData,
        selectedModulePartIds: prevData.selectedModulePartIds.filter((id) => id !== modulePart.id),
      }));
    }
  };

  //Initializes the customer identifier, the advoModuleName and the licenseId from the URL
  function initializeUrlParameters() {
    let tmpCIdent = getParameterValueFromUrl("customerIdentifier");
    setCustomerIdentifier(tmpCIdent);
    setAdvoModuleName(getParameterValueFromUrl("advoModuleName"));
  }

  //Extracts the parameter value from the URL and returns it
  //If parameter is not found, logs an error and returns null instead
  function getParameterValueFromUrl(param) {
    const url = new URL(window.location.href);
    const value = url.searchParams.get(param);

    //Value not found in url, try sessionStorage
    if (!value) {
      let sessionStorageValue = window.sessionStorage.getItem(param);
      if (sessionStorageValue) {
        return sessionStorageValue;
      }
      toast.error("Fehler: " + param + " nicht gefunden. Bitte starten Sie den Vorgang erneut.");
      return null;
    }

    //Save the param in sessionStorage so it can be accessed after a page reload. Gets cleared whenever tab is closed
    window.sessionStorage.setItem(param, value);
    return value;
  }

  //Fetches the customer data and the latest license terms from the server and updates the state so the data gets rendered
  async function fetchInitialData() {
    try {
      //Construct Promise Array to wait for all fetches to finish
      const fetchPromises = [
        //Fetches the customer data
        fetchCheckAndSetData(
          API_URL +
            "/CustomerData/CustomerData/?customerIdentifier=" +
            customerIdentifier +
            "&advoModuleName=" +
            advoModuleName,
          setCustomerData
        ),
        //Fetches the module parts
        fetchCheckAndSetData(API_URL + "/AdvoModule/ModuleParts/" + advoModuleName, setModuleParts),
        //Fetches the latest license terms
        fetchCheckAndSetData(API_URL + "/Document/LatestLicenseTerms", setLicenseTerms),
      ];

      //After all fetches are done, set isLoading to false
      await Promise.all(fetchPromises);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching data: ", error);
      toast.error("Fehler beim Abrufen der Daten.");
    }
  }

  //Fetches the data from the backend API, checks the response and sets the data accordingly
  async function fetchCheckAndSetData(url, setDataFunction) {
    const headers = new Headers();
    const options = {
      method: "GET",
      headers: headers,
    };

    //Checks if the response was successful and updates state and data accordingly If one request fails, the error gets logged and the others continue
    try {
      const responseData = await fetch(url, options);
      //React here to other response codes than 200
      if (!responseData.ok) {
        const responseText = await responseData.text();

        //If the customerIdentifier is not found it can mean the customer is not registered yet, so no error message is shown
        if (responseText.includes("No Customer with customerIdentifier", 0)) {
          return;
        }

        //If the response is not 404 (not found), show an error message. 404s are handled differently
        if (responseData.status !== 404) {
          toast.error(responseData.status + " " + responseText + " (url: " + url + ")");
        }
      } else {
        const responseJson = await responseData.json();
        setDataFunction(responseJson);
      }
    } catch (error) {
      console.log("An unexpected error occurred: ", error);
    }
  }

  //Function to send updated license and licenseParts to the backend API.
  //apiEndpoint: The endpoint of the API to send the data to (part of the url)
  async function putCustomerData(apiEndpoint) {
    //Send the data to the API via a PUT request, needs the access token for authorization
    try {
      const responseData = await fetch(API_URL + apiEndpoint, {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerIdentifier: customerIdentifier,
          name: customerData.name,
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          street: customerData.street,
          postcode: customerData.postcode,
          location: customerData.location,
          phoneNumber: customerData.phoneNumber,
          fax: customerData.fax,
          email: customerData.email,
          numberOfLocations: customerData.numberOfLocations,
          licenseId: customerData.licenseId,
          selectedModulePartIds: getSelectedAndRequiredModulePartIds(),
        }),
      });

      //If the response was not successful, log the error, show it in toast-message and return false
      if (!responseData.ok) {
        const responseText = await responseData.text();
        console.log("Error sending data:", responseData.status, responseText);
        toast.error(`Fehler beim Senden der Daten: ${responseData.status} - ${responseText}`);
        return false;
      }

      return true;
    } catch (error) {
      console.log("An unexpected error occurred: ", error);
      return false;
    }
  }

  //Send the data to the backend API.
  async function submitCustomerLicenseForm() {
    const sentToast = toast.info("Lizenz wird beauftragt...");

    //Check if there is already an existing License
    //If there is no License, create a new one
    if (customerData.licenseId === -1) {
      if (!(await putCustomerData("/CustomerData/CreateNewLicense"))) {
        toast.dismiss(sentToast);
        toast.error("Fehler beim Erstellen der Lizenz.");
        return;
      }
    }
    //If there is a license, update it
    else {
      if (!(await putCustomerData("/CustomerData/UpdateCustomerData"))) {
        toast.dismiss(sentToast);
        toast.error("Fehler beim Aktualisieren der Lizenz.");
        return;
      }
    }

    //License was sent successfully, response is OK
    setLicenseWasSent(true);
    toast.dismiss(sentToast);
    toast.success("Ihre Lizenz wurde erfolgreich beauftragt.");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }

  //Downloads Documents such as LicenseTerms or AGBs. 'documentType' is an enum that specifies which document to download: 1 = LicenseTerms, 2 = AGBs
  async function downloadDocument(documentType) {
    setWaitingForDocumentTypeDownload((prev) => [...prev, documentType]);

    let url = API_URL + "/Document/GetPDF/" + documentType;

    try {
      const responseData = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/pdf",
          responseType: "application/pdf",
        },
      });

      //Checks if the response was successful and updates state and data accordingly If one request fails, the error gets logged and the others continue
      if (!responseData.ok) {
        //React here to other response codes than 200, currently not needed
        toast.error("Fehler: " + responseData.status + ": " + (await responseData.text()) + " (url: " + url + "");
      } else {
        //If the response was successful, the pdf gets downloaded
        const responseString = "data:application/pdf;base64," + (await responseData.text());
        const downloadLink = document.createElement("a");

        let fileName;
        if (documentType === 1) {
          fileName = "Lizenzbedingungen";
        } else if (documentType === 2) {
          fileName = "AGB";
        }
        fileName = advoModuleName + "_" + fileName + "_" + new Date(Date.now()).toLocaleDateString("de-DE") + ".pdf";

        downloadLink.href = responseString;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
        setWaitingForDocumentTypeDownload((prev) => prev.filter((type) => type !== documentType));
      }
    } catch (error) {
      toast.error("Ein unerwarteter Fehler ist aufgetreten.");
      console.log("An unexpected error occurred: ", error);
    }
  }

  //Returns the IDs of the selected optional ModuleParts together with the IDs of the required ModuleParts
  function getSelectedAndRequiredModulePartIds() {
    var requiredModulePartIds = [];
    moduleParts.forEach((modulePart) => {
      if (modulePart.required) {
        if (!customerData.selectedModulePartIds.includes(modulePart.id)) {
          requiredModulePartIds.push(modulePart.id);
        }
      }
    });
    return customerData.selectedModulePartIds.concat(requiredModulePartIds);
  }

  //----------------------------- Return App component -----------------------------

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <header className="App-header">
        <h1>Lizenzverwaltung - {advoModuleName}</h1>
      </header>
      <div>
        {/* Thank-you section, after a new License-request was sent*/}
        <div id="thank-you" style={{ display: licenseWasSent ? "block" : "none" }}>
          <h2>
            Ihre Lizenz wurde erfolgreich beauftragt.
            <br />
            <br />
            Vielen Dank, dass Sie {advoModuleName} nutzen.
            <br />
            <br />
            Sie erhalten in Kürze eine Bestätigungsmail.
          </h2>
        </div>
        {/* If no module parts are found, display an error message*/}
        {isLoading ? (
          <Loading text={"Lädt"} />
        ) : moduleParts.length === 0 ? (
          <h2>
            Es existiert kein AdvoModul mit diesem Namen: {advoModuleName}
            <br />
            Bitte starten Sie den Vorgang erneut oder kontaktieren Sie AdvoService.
          </h2>
        ) : (
          /* The form for customer data and license data*/
          <form
            id="complete-form"
            style={{ color: licenseWasSent ? "grey" : "black" }}
          >
            {/* The form for customer data*/}
            <div id="customer-license-form">
              <CustomerData
                customerData={customerData}
                handleCustomerDataChange={handleCustomerDataChange}
                licenseWasSent={licenseWasSent}
              />
              {/* The module parts section*/}
              <ModuleParts
                modulePartsData={moduleParts}
                selectedModulePartIds={customerData.selectedModulePartIds}
                onModulePartSelection={(modulePartId, checked) => handleModulePartSelection(modulePartId, checked)}
                numberOfLocations={customerData.numberOfLocations}
                licenseWasSent={licenseWasSent}
              />
            </div>
            {/* The license terms section*/}
            <LicenseTerms
              onSubmit={submitCustomerLicenseForm}
              licenseTerms={licenseTerms}
              licenseWasSent={licenseWasSent}
              DownloadDocument={downloadDocument}
              waitingForDocumentTypeDownload={waitingForDocumentTypeDownload}
            />
          </form>
        )}
      </div>
    </div>
  );
}

export default App;
